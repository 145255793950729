// store/index.js
import { createStore } from 'vuex';
import { UserManager } from 'oidc-client';
import { useRouter } from 'vue-router';
import VueJwtDecode from "vue-jwt-decode";

const oneLoginConfig = {
    authority: process.env.ONELOGIN_URL,
    client_id: process.env.ONELOGIN_CLIENT_ID,
    client_secret: process.env.ONELOGIN_CLIENT_SECRET,
    redirect_uri: process.env.ONELOGIN_REDIRECT_CALLBACK,
    response_type: 'id_token',
    scope: 'openid profile email',
};
export const userManager = new UserManager(oneLoginConfig);

export default createStore({
    state: {
        user: null,
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        CLEAR_USER(state) {
            state.user = null;
        },
        SET_LAST_ACTIVITY(state, timestamp) {
            state.lastActivity = timestamp;
        },
    },
    actions: {
        async login({ commit }) {
            try {
                const user = await userManager.signinRedirect();
                commit('SET_USER', user);
            } catch (error) {
                console.error('Authentication error:', error);
            }
        },
        async logout({ commit }) {
            try {
                commit('CLEAR_USER');

                localStorage.removeItem('user');
            } catch (error) {
                console.error('Logout error:', error);
            }
        },
        async callback({ commit }) {
            const router = useRouter();
            try {
                // Handle the OneLogin OIDC authentication response
                const user = await userManager.signinRedirectCallback();
                const idToken = user.id_token;
                const decodedToken = VueJwtDecode.decode(idToken);
                const roles = decodedToken.roles;
                if (roles && roles.includes('Trackmap Users')) {
                    commit('SET_USER', user);
                    localStorage.setItem('user', JSON.stringify(user));
                    await router.push('/');
                } else {
                    commit('CLEAR_USER');
                    await router.push({name: 'Login', query: { error: "You don't have permission to access the Trackmap. Contact the administrator to get access."}})
                }
            } catch (error) {
                commit('CLEAR_USER');
                // Handle authentication error
                console.error('Authentication error:', error);

                // Redirect the user to the login page or show an error message
                await router.push({name: 'Login', query: { error: "You don't have permission to access the Trackmap. Contact the administrator to get access. " + error}})
            }
        },
        async checkToken({ commit }) {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                try {
                    const user = JSON.parse(storedUser);
                    commit('SET_USER', user);
                    commit('SET_LAST_ACTIVITY', Date.now());
                } catch (error) {
                    console.error('Error parsing stored user data:', error);
                    commit('CLEAR_USER');
                }
            } else {
                commit('CLEAR_USER');
            }
        },
    },
    getters: {
        isAuthenticated: (state) => !!state.user,
        user: (state) => state.user,
    },
});
