<template>
  <div class="gandola-details">
    <h6 class="h6">Details</h6>
    <p class="small">
      <strong>Gandola #</strong>: {{gandola.id}}<br/>
      <strong>People on board</strong>: {{gandola.people? gandola.people : 0}}<br/>
      <strong>Strollers</strong>: {{gandola.stroller? gandola.stroller : 0}}<br/>
      <strong>is a Wheel Chair?</strong>: {{gandola.wav? 'Yes' : 'No'}}
    </p>
    <button class="btn btn-sm btn-dark" @click="closeDetails">OK</button>
  </div>
</template>

<script>
export default {
  props: {
    gandola: Object,
  },
  methods: {
    closeDetails() {
      this.$emit('close-details');
    },
  },
};
</script>

<style scoped>
.gandola-details {
  position: fixed;
  top: 133px;
  left: 270px;
  background: #FFF;
  border: 1px solid #CCC;
  width: 200px;
  height: 200px;
  padding: 10px;
  z-index: 9999;
}
</style>