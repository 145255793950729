<template>
  <section class="container-fluid wrapper h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 text-center">
        <h1><img src="../assets/img/skyliner-logo.png" style="height: 150px" /></h1>
        Loading...
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    // Call the signinRedirectCallback on component mount
    onMounted(async () => {
        store.dispatch('callback');
    });

    return {};
  },
};
</script>
