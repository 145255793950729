<template>
  <base-map></base-map>
</template>

<script>
import BaseMap from '../components/BaseMap.vue'
export default {

  name: 'App',
  components: {
    'base-map': BaseMap,
  }
}
</script>