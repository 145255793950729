<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    blink: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const showDetails = () => {
      emit('gandola-clicked', props.data);
    };

    return {
      showDetails,
    };
  },
};
</script>

<template>
  <transition name="animate">
    <div :style="{top: data.location.y + 'px', left: data.location.x + 'px'}" class="gandola" :class="{ 'gwd': data.type !== 2, 'gandola': true, 'blink': blink, 'occupied': data.people > 0, 'occupied-gwd': data.type !== 2 && data.people > 0 }" :id="data.id" @click="showDetails">
      {{data.id}}
    </div>
  </transition>
</template>

<style scoped>

.gandola {
  position: absolute;
  background-image: url("../assets/img/gray-gondola-bg-original.png");
  background-size: cover;
  width: 70px;
  height: 68px;
  color: #FFF;
  font-size: 20px;
  font-weight: bold;
  z-index: 9998;
  padding: 16px 0 0;
  text-align: center;
  cursor: pointer;
}

.gwd {
  background-image: url("../assets/img/gwd-gondola-bg-10.png");
  color: #000;
}

.occupied {
  background-image: url("../assets/img/gandola.svg");
  color: #FFF;
}

.occupied-gwd {
  background-image: url("../assets/img/gwd-gondola-bg-10.png") !important;
  color: dodgerblue !important;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  background-color: #ff5b20;
  color: #1b1e21;
  background-image: none !important;
  z-index: 9999;
  width: 120px;
  height: 120px;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@media (max-width: 767px) {
  .gandola {
    font-size: 7px;
  }
}

</style>