import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

// Plugins
import backend from './plugins/backend';


// createApp.config.productionTip = false;

const app = createApp(App);

app.use(router);
store.dispatch('checkToken').then(() => {
    document.addEventListener('click', () => {
        store.commit('SET_LAST_ACTIVITY', Date.now());
    });

    document.addEventListener('keypress', () => {
        store.commit('SET_LAST_ACTIVITY', Date.now());
    });

    const checkActivityInterval = 60 * 1000; // 1 minute
    setInterval(() => {
        const currentTime = Date.now();
        const lastActivity = store.state.lastActivity;

        // Adjust the idleTimeout value to the desired inactivity period in milliseconds (e.g., 1 hour)
        const idleTimeout = 60 * 60 * 1000;

        // Log out the user if the session has been inactive for longer than the idleTimeout
        if (lastActivity && currentTime - lastActivity > idleTimeout) {
            store.dispatch('logout').then(() => {});
        }
    }, checkActivityInterval);

    // Mount the app only after the checkToken action is completed
    app.use(store);
    app.use(backend);

    app.mount('#app');
});
