<!-- LogoutButton.vue -->
<template>
  <button @click="logout" class="btn btn-danger mt-lg-3 mt-sm-1 mr-1">Logout</button>
</template>

<script>
export default {
  methods: {
    logout() {
      // Dispatch the 'logout' action from the Vuex store
      this.$store.dispatch('logout');
      // Redirect the user to the login page or any other desired route
      this.$router.push('/login');
    },
  },
};
</script>
