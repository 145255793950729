<script>
import Gandola from "@/components/GandolaContainer.vue";
import GandolaDetails from '@/components/GandolaDetails.vue';
import LogoutButton from './LogoutButton.vue';

export default {
  components: {
    Gandola,
    GandolaDetails,
    LogoutButton,
  },
  props: {
    delay: {
      type: Number,
      default: 600
    },
    zoomDelay: {
      type: Number,
      default: 300
    },
  },
  data() {
    return {
      socket: null,
      connected: false,
      zoomLevel: 25,
      gandolas: [],
      peopleCount: [],
      totalPeople: 0,
      searchQuery: '',
      highlightedGandolaId: null,
      gandolaSelected: null,
      timeIntervalGandolaApi: null,
      timeIntervalPeopleCountApi: null,
      header: {
        'Authorization': process.env.BACKEND_API_KEY
      },
      lineInfo: true,
    }
  },
  setup() {
    let lineCounts = {};
    let lines=  ['TIG', 'TPD', 'THS'];

    lineCounts['TIG'] = {
      totalNormal: 0,
      totalGwd: 0,
      speed: 0,
      people: 0,
    };

    lineCounts['TPD'] = {
      totalNormal: 0,
      totalGwd: 0,
      speed: 0,
      people: 0,
    };

    lineCounts['THS'] = {
      totalNormal: 0,
      totalGwd: 0,
      speed: 0,
      people: 0,
    };

    return {
      lines,
      lineCounts
    }
  },
  computed: {
    zoomStyle() {
      return {
        zoom: `${this.zoomLevel}%`,
      }
    }
  },
  mounted() {

    // Call gandola's api
    this.callGandolaApi();

    // call people count api
    this.callPeopleCountApi();

    // Call the Gandola API every 1 seconds
    this.timeIntervalGandolaApi = setInterval(async () => {
          try {
            await this.callGandolaApi();
            this.connected = true
          } catch (error) {
            this.connected = false
            console.error('Error during interval fetch:', error);
          }}, 1000);

    // Call the Gandola API every 30 seconds
    this.timeIntervalPeopleCountApi = setInterval(async () => {
      try {
        await this.callPeopleCountApi();
      } catch (error) {
        console.error('Error during interval fetch:', error);
      }
    }, 10 * 1000);
  },
  beforeUnmount() {
    clearInterval(this.timeIntervalGandolaApi);
    clearInterval(this.timeIntervalPeopleCountApi);
  },
  methods: {
    handleSearch() {
      let searchQuery = this.searchQuery;
      searchQuery = searchQuery.replace(/\D/g, ''); // Remove non-numeric characters

      if (searchQuery) {
        const matchedGandola = this.gandolas.find(gandola => gandola.id === searchQuery);

        if (matchedGandola) {
          this.highlightedGandolaId = matchedGandola.id;
          setTimeout(() => {
            this.highlightedGandolaId = null;
          }, 30000);

          switch (matchedGandola.line) {
            case 1:
              this.goToEpcotLine();
              break;
            case 2:
              this.goToResortLine();
              break;
            case 3:
              this.goToHSLine();
              break;
          }
        }
      }
    },
    zoomIn() {
      this.zoomLevel += 5;
    },
    zoomOut() {
      if (this.zoomLevel > 25) {
        this.zoomLevel -= 5;
      }
    },
    goToEpcotLine() {
      setTimeout(() => {
        this.zoomLevel = 25;
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }, this.zoomDelay)
      }, this.delay);
    },
    goToHSLine() {
      setTimeout(() => {
        this.zoomLevel = 30;
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight / 2 - 500,
            left: 0,
            behavior: 'smooth'
          });
        }, this.zoomDelay)
      }, this.delay);
    },
    goToResortLine() {
      setTimeout(() => {
        this.zoomLevel = 25;
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight - 1400,
            left: 0,
            behavior: 'smooth'
          });
        }, this.zoomDelay)
      }, this.delay);
    },
    update(updatedData) {
      const gandolaToUpdateIndex = this.gandolas.findIndex(gandola => gandola.id === updatedData.id);
      if (gandolaToUpdateIndex !== -1) {
        const gandolaToUpdate = Object.assign({}, this.gandolas[gandolaToUpdateIndex], updatedData);
        gandolaToUpdate.people = 0;
        gandolaToUpdate.stroller = 0;
        gandolaToUpdate.wav = 0;

        const peopleOnBoardIndex = this.peopleCount.findIndex(people => people.id === updatedData.id);

        if (peopleOnBoardIndex !== -1) {
          let peopleObj = this.peopleCount[peopleOnBoardIndex];
          gandolaToUpdate.people = peopleObj.people;
          gandolaToUpdate.stroller = peopleObj.stroller;
          gandolaToUpdate.wav = peopleObj.wav;
        }

        this.gandolas.splice(gandolaToUpdateIndex, 1, gandolaToUpdate);
      } else {
        updatedData.people = 0;
        updatedData.stroller = 0;
        updatedData.wav = 0;
        this.gandolas.push(updatedData);
      }
    },
    updateLineTotals() {
      this.totalPeople = 0;
      this.lines.forEach(line => {
        this.lineCounts[line] = {
          totalNormal: 0,
          totalGwd: 0,
          speed: this.lineCounts[line].speed,
          people: 0,
        };
      });

      this.gandolas.reduce((counts, gandola) => {
        let lineKey = this.getLineName(gandola.line);

        if (gandola.type === 2) {
          this.lineCounts[lineKey].totalNormal++;
        } else {
          this.lineCounts[lineKey].totalGwd++;
        }

        const peopleOnBoardIndex = this.peopleCount.findIndex(people => people.id === gandola.id);

        if (peopleOnBoardIndex !== -1) {
          this.lineCounts[lineKey].people += this.peopleCount[peopleOnBoardIndex].people;
        }
      }, {});

      this.lines.forEach(line => {
        this.totalPeople += this.lineCounts[line].people;
      });
    },
    getLineName(line) {
      let lineKey;
      switch (line) {
        case 1:
          lineKey = 'TIG';
          break;
        case 2:
          lineKey = 'TPD';
          break;
        case 3:
          lineKey = 'THS';
          break;
        default:
          lineKey = 'Other';
      }

      return lineKey;
    },
    async callGandolaApi() {
      try {
        const response = await this.backend.get('/gandola/positions', {
          headers: this.header
        });
        if (response.status === 200) {
          let json = response.data;
          json.data.forEach(data => {
            this.update(data);
          });
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

      this.updateLineTotals();

      try {
        const response = await this.backend.get('/ropeway/speed', {
          headers: this.header
        });
        if (response.status === 200) {
          let json = response.data;
          json.data.forEach(ropeway => {
            let lineKey = this.getLineName(ropeway.line);
            this.lineCounts[lineKey].speed = ropeway.speedMeterInSecond;
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async callPeopleCountApi() {
      try {
        const response = await this.backend.get('/people-count', {
          headers: this.header
        });
        if (response.status === 200) {
          let data = response.data.monitoring;

          this.peopleCount = [];
          Object.keys(data).forEach(key => {
            data[key].id = key
            this.peopleCount.push(data[key]);

          });
          this.updateLineTotals();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    showDetails(gandola) {
      const peopleOnBoardIndex = this.peopleCount.findIndex(g => g.id === gandola.id);
      if (peopleOnBoardIndex !== -1) {
        this.gandolaSelected = Object.assign({}, gandola, this.peopleCount[peopleOnBoardIndex]);
      } else {
        this.gandolaSelected = gandola;
      }
    },
    closeDetails() {
      this.gandolaSelected = null;
    },
    closeLineInfo() {
      this.lineInfo = false;
    },
    showLineInfo() {
      this.lineInfo = true;
    },
  }
}
</script>

<template>

  <div class="infos container-fluid">
    <div class="row">
      <div class="col-5">
        <h1><img src="../assets/img/skyliner-logo.png" style="height: 80px" alt="Skyliner" /></h1>
      </div>

      <div class="d-lg-block d-md-block d-xl-block col-xs-3">
        <div class="form-group mb-0">
          <label>Search by cabin number:</label>
          <div class="input-group">
            <input type="text" class="form-control" id="search" placeholder="ex: 024" v-model="searchQuery" />
            <button class="btn btn-dark m-l-5" @click="handleSearch">OK</button>
          </div>
          <span class="text-muted small">The cabin will blink by 30 seconds with color <strong style="color: #ff5b20">orange</strong></span>
        </div>
      </div>

      <div class="d-none d-lg-block d-md-block d-xl-block col-xs-4 col-sm-4 col-md-4 col-lg-4 text-right">
        <div>
          <button id="ecLine" class="btn btn-primary mt-lg-3 mt-sm-1 mr-1" @click="goToEpcotLine">TIG</button>
          <button id="hsLine" class="btn btn-dark mt-lg-3 mt-sm-1 mr-1" @click="goToHSLine">THS</button>
          <button id="rtLine" class="btn btn-warning mt-lg-3 mt-sm-1 mr-1" @click="goToResortLine">TPD</button>

          <button id="plusBtn" class="btn btn-secondary mt-lg-3 mt-sm-1 mr-1" @click="zoomIn">Zoom In</button>
          <button id="minusBtn" class="btn btn-secondary mt-lg-3 mt-sm-1 mr-1" @click="zoomOut">Zoom Out</button>
          <logout-button />
        </div>
      </div>
    </div>
  </div>

  <GandolaDetails v-if="gandolaSelected" :gandola="gandolaSelected" @close-details="closeDetails" />

  <button id="showInfoContainer" class="btn btn-dark" @click="showLineInfo" v-if="!lineInfo">Line details</button>
  <div class="line-info" v-if="lineInfo">
      <button class="btn btn-link" @click="closeLineInfo">X</button>
      <div v-for="lineName in lines" :key="lineName" class="d-block">
        <h6 class="h6 text-primary">{{ lineName }}</h6>
        <p class="small">
          <strong>Total in line:</strong> {{ lineCounts[lineName].totalNormal + lineCounts[lineName].totalGwd }}<br/>
          <strong>Regular:</strong> {{ lineCounts[lineName].totalNormal }}<br/>
          <strong>GWD:</strong> {{ lineCounts[lineName].totalGwd }}<br/>
          <strong>People on board:</strong> {{ lineCounts[lineName].people }}<br/>
          <strong>Speed:</strong> <span :class="lineCounts[lineName].speed.toFixed(0) === '0' ? 'text-danger' : 'text-primary'">{{ lineCounts[lineName].speed.toFixed(0) }} m/s</span>
        </p>
        <hr/>
      </div>
      <p>Total People: {{this.totalPeople}}</p>
  </div>

  <div class="map-base-image zoomable position-relative" id="base-map" :style="zoomStyle">
    <Gandola v-for="gandola in gandolas" :key="gandola.id" :data="gandola" ref="gandolas" :blink="gandola.id === highlightedGandolaId" @gandola-clicked="showDetails" />
  </div>

</template>

<style scoped>
.map-base-image {
  background-image: url("../assets/img/skyliner-map-lr.png");
  width: 7472px;
  height: 10120px;
  position: relative;
  background-size: 7472px 10120px;
  background-repeat: no-repeat;
  background-position-x: 100px;
  background-position-y: 100px;
  zoom: 25%;
}

.zoomable {
  transition: transform 0.5s ease;
}

.infos {
  position: fixed;
  top: 0;
  background: #FFF;
  padding: 15px;
  z-index: 9999;
  border: 1px solid #CCC;
}

.line-info {
  position: fixed;
  top: 133px;
  left: 10px;
  background: #FFF;
  padding: 15px;
  z-index: 9999;
  border: 1px solid #CCC;
  width: 250px;
}

#showInfoContainer {
  position: fixed;
  top: 133px;
  left: 10px;
  z-index: 9999;
}

.line-info .btn {
  position: absolute;
  top: 5px;
  right: 10px;
}

.line-info > div {
  width: 100%;
}
</style>