import axios from "axios";

const backend = {
    install(Vue) {
        Vue.mixin({
            data() {
                return {
                    backend: axios.create({
                        baseURL: process.env.WEBSERVICE_BACKEND_URL,
                        withCredentials: true,
                    }),
                }
            },
            created() {
                this.backend.withCredentials = true;
                // Response interceptor to handle 4xx errors. Certain errors returned from the server require the user be
                // redirected to another page.
                this.backend.interceptors.response.use(
                    response => {
                        return response;
                    },
                    error => {
                        // If the user is not authorized, redirect to MyID
                        if ('status' in error.response) {
                            if (error.response.status === 400) {
                                // Server id is missing in session
                                // Route user to the server selection page
                                window.location.href = '/';
                            }
                            else if (error.response.status === 401) {
                                // The user is unauthorized
                                // Route user to MyID for login
                                window.location.href = '/login'
                            }
                            else if (error.response.status === 403) {
                                // The ser doesn't have the required role or permissions to access the resource
                                // Route user to the server selection page
                                window.location.href = '/login';
                            }
                            else {
                                // Return any other error
                                return error.response;
                            }
                        }
                    }
                );
            },
        });
    }
}

export default backend;
