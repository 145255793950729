<template>
  <section class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 text-center">
        <p v-if="errorMessage" class="alert alert-danger">{{errorMessage}}</p>
        <h1><img :src="require('@/assets/img/skyliner-logo.png')" style="height: 150px" alt="Skyliner"></h1>
        <a class="btn btn-primary btn-custom w-md waves-effect waves-light mt-2" @click="handleLogin">Login With <img :src="require('@/assets/img/myid.png')" alt="MyID"></a>
      </div>
    </div>
  </section>
</template>

<style>

</style>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  data() {
    return {
      errorMessage: null,
    };
  },
  created() {
    // Check if an error message is present in the query parameters
    const error = this.$route.query.error;
    if (error) {
      // Set the error message in the component's data
      this.errorMessage = error;
    }
  },
  setup() {
    const store = useStore();

    const handleLogin = () => {
      store.dispatch('login');
    };

    return {
      handleLogin,
    };
  },
});
</script>